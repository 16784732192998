<template>
  <div class="MainOverview">
    <!-- Loading page / Error page if shifts can not be loaded -->
    <div v-if="loadingShifts || loadingShiftsError" class="LoadingUp">
      <div class="Upper">
        <div v-if="!loadingShiftsError">
          <i class="fad fa-tasks-alt fa-3x"></i>
        </div>
        <div v-if="loadingShiftsError">
          <i class="fas fa-exclamation fa-3x"></i>
        </div>
      </div>
      <div class="Middle">
        <div v-if="!loadingShiftsError">Arbeitszeit wird geladen</div>
        <div v-if="loadingShiftsError">Fehler beim Laden deiner Arbeitszeit</div>
      </div>
      <div class="Lower">
        <div v-if="!loadingShiftsError">
          <img src="loading-balls-line.gif" />
        </div>
        <div v-if="loadingShiftsError" style="">
          <small style="color: #ccc">HTTP Code: {{ errorHttpCode }}</small
          ><br />
          <small style="color: #ccc">{{ errorMessage }}</small
          ><br />
        </div>
      </div>
    </div>

    <!-- Some Admin tampered the actualshifts in the future, abort and cancel all actions -->
    <div v-if="tamperedByAdmin" class="LoadingUp">
      <div class="Upper">
        <div><i class="fad fa-exclamation fa-3x"></i></div>
      </div>
      <div class="Middle">
        <div>Deine zukünftige Arbeitszeit wurde bereits eingetragen</div>
      </div>
      <div class="Lower">
        <div><small>Derzeit keine Aktion am Terminal möglich</small></div>
      </div>
    </div>

    <!-- An actualshift start is already planned and inserted -->
    <div v-if="loginPunctual" class="LoadingUp">
      <div class="Upper">
        <div>
          <i class="fad fa-house-return fa-3x" style="color: #6d8d23"></i>
        </div>
      </div>
      <div class="Middle">
        <div style="color: #6d8d23">
          Du wirst um {{ loginPunctualMoment.format("HH:mm") }} Uhr automatisch
          eingestempelt
        </div>
      </div>
      <div class="Lower">
        <div><small>Derzeit keine Aktion am Terminal möglich</small></div>
      </div>
    </div>

    <!-- An actualshift start is already planned and inserted -->
    <div v-if="logoutPunctual" class="LoadingUp">
      <div class="Upper">
        <div>
          <i class="fad fa-house-leave fa-3x" style="color: #e6154f"></i>
        </div>
      </div>
      <div class="Middle">
        <div style="color: #e6154f">
          Du wirst um {{ logoutPunctualMoment.format("HH:mm") }} Uhr automatisch
          ausgestempelt
        </div>
      </div>
      <div class="Lower">
        <div><small>Derzeit keine Aktion am Terminal möglich</small></div>
      </div>
    </div>

    <!-- An actualshift start is already planned and inserted -->
    <div v-if="actionPerformedRedirectToLogin" class="LoadingUp">
      <div class="Upper">
        <div>
          <i class="fad fa-check-circle fa-3x" style="color: #6d8d23"></i>
        </div>
      </div>
      <div class="Middle">
        <div style="color: #6d8d23">{{ successMessage }}</div>
      </div>
      <div class="Lower" v-if="$parent.employeeFeedback && didLogout" style="color: #aaa">
        Wie fühlst du dich nach deiner Schicht?
        <div style="padding: 20px">
          <i
            class="fad fa-tired fa-3x"
            style="color: #e6154f; padding-left: 20px; padding-right: 20px"
            v-on:click="Rating(1)"
          ></i>
          <i
            class="fad fa-frown fa-3x"
            style="color: #e64615; padding-left: 20px; padding-right: 20px"
            v-on:click="Rating(2)"
          ></i>
          <i
            class="fad fa-meh fa-3x"
            style="color: #e7a413; padding-left: 20px; padding-right: 20px"
            v-on:click="Rating(3)"
          ></i>
          <i
            class="fad fa-smile fa-3x"
            style="color: #809b1f; padding-left: 20px; padding-right: 20px"
            v-on:click="Rating(4)"
          ></i>
          <i
            class="fad fa-laugh-beam fa-3x"
            style="color: #5ea021; padding-left: 20px; padding-right: 20px"
            v-on:click="Rating(5)"
          ></i>
        </div>
      </div>
      <div class="Lower" v-if="!$parent.employeeFeedback || !didLogout">
        <div>
          <small>{{ successSubMessage }}</small>
        </div>
      </div>
    </div>

    <div
      v-if="
        !loadingShifts &&
        !loadingShiftsError &&
        !tamperedByAdmin &&
        !loginPunctual &&
        !logoutPunctual &&
        !actionPerformedRedirectToLogin
      "
    >
      <div class="LoadingEmployeeData" v-if="false">
        <div class="Upper">
          <i class="fas fa-circle-notch fa-spin fa-2x"></i>
        </div>
        <div class="Lower">Mitarbeiter Daten werden geladen...</div>
      </div>

      <div class="EmployeeData" v-if="true">
        <div class="Center" v-if="currentPlanshift != null && !$parent.supervisorLogin">
          <div class="Upper">
            <img
              src="profileImageSmall.png"
              class="Image"
              v-if="
                $parent.employeePictureServer == null ||
                $parent.employeePictureFileId == null ||
                $parent.employeePictureAccessToken == null
              "
            />
            <img
              v-bind:src="
                $parent.employeePictureServer +
                '/api/file?id=' +
                $parent.employeePictureFileId +
                '&accessToken=' +
                $parent.employeePictureAccessToken +
                '&thumb=medium'
              "
              class="Image"
              v-if="
                $parent.employeePictureServer != null &&
                $parent.employeePictureFileId != null &&
                $parent.employeePictureAccessToken != null
              "
            />
          </div>
          <div class="Lower">
            {{ $parent.employeeFirstName }}
            <span class="LastName">{{ $parent.employeeLastName }}</span>
          </div>
        </div>
        <div class="Left" v-if="currentPlanshift != null && $parent.supervisorLogin">
          <div class="Upper">
            <img
              src="profileImageSmall.png"
              class="Image"
              v-if="
                $parent.employeePictureServer == null ||
                $parent.employeePictureFileId == null ||
                $parent.employeePictureAccessToken == null
              "
            />
            <img
              v-bind:src="
                $parent.employeePictureServer +
                '/api/file?id=' +
                $parent.employeePictureFileId +
                '&accessToken=' +
                $parent.employeePictureAccessToken +
                '&thumb=medium'
              "
              class="Image"
              v-if="
                $parent.employeePictureServer != null &&
                $parent.employeePictureFileId != null &&
                $parent.employeePictureAccessToken != null
              "
            />
          </div>
          <div class="Lower">
            {{ $parent.employeeFirstName }}
            <span class="LastName">{{ $parent.employeeLastName }}</span>
          </div>
        </div>
        <div class="Right" v-if="currentPlanshift != null && $parent.supervisorLogin">
          <div class="Upper">
            <img
              src="profileImageSmall.png"
              class="Image"
              v-if="
                $parent.supervisorPictureServer == null ||
                $parent.supervisorPictureFileId == null ||
                $parent.supervisorPictureAccessToken == null
              "
            />
            <img
              v-bind:src="
                $parent.supervisorPictureServer +
                '/api/file?id=' +
                $parent.supervisorPictureFileId +
                '&accessToken=' +
                $parent.supervisorPictureAccessToken +
                '&thumb=medium'
              "
              class="Image"
              v-if="
                $parent.supervisorPictureServer != null &&
                $parent.supervisorPictureFileId != null &&
                $parent.supervisorPictureAccessToken != null
              "
            />
          </div>
          <div class="Lower">
            {{ $parent.supervisorFirstName }}
            <span class="LastName">{{ $parent.supervisorLastName }}</span>
          </div>
        </div>

        <div class="Left" v-if="currentPlanshift == null">
          <div class="Upper">
            <img
              src="profileImageSmall.png"
              class="Image"
              v-if="
                $parent.employeePictureServer == null ||
                $parent.employeePictureFileId == null ||
                $parent.employeePictureAccessToken == null
              "
            />
            <img
              v-bind:src="
                $parent.employeePictureServer +
                '/api/file?id=' +
                $parent.employeePictureFileId +
                '&accessToken=' +
                $parent.employeePictureAccessToken +
                '&thumb=medium'
              "
              class="Image"
              v-if="
                $parent.employeePictureServer != null &&
                $parent.employeePictureFileId != null &&
                $parent.employeePictureAccessToken != null
              "
            />
          </div>
          <div class="Lower">
            {{ $parent.employeeFirstName }}
            <span class="LastName">{{ $parent.employeeLastName }}</span>
          </div>
        </div>
        <div class="Right" v-if="currentPlanshift == null && !$parent.supervisorLogin">
          <div class="Header">Nächste geplante Schicht</div>
          <div class="Body" v-if="nextPlanshift == null">
            In den nächsten 48 Stunden<br />
            gibt es keine geplante Schicht
          </div>
          <div class="Body" v-if="nextPlanshift != null">
            {{ nextPlanshiftMomentFrom.format("dddd") }},
            {{ nextPlanshiftMomentFrom.format("DD") }}.
            {{ nextPlanshiftMomentFrom.format("MMMM") }}
            {{ nextPlanshiftMomentFrom.format("YYYY") }}<br />
            {{ nextPlanshiftMomentFrom.format("HH:mm") }} -
            {{ nextPlanshiftMomentTo.format("HH:mm") }}<br />
            {{ positionText }}<br />
          </div>
        </div>
        <div class="Right" v-if="$parent.supervisorLogin">
          <div class="Upper">
            <img
              src="profileImageSmall.png"
              class="Image"
              v-if="
                $parent.supervisorPictureServer == null ||
                $parent.supervisorPictureFileId == null ||
                $parent.supervisorPictureAccessToken == null
              "
            />
            <img
              v-bind:src="
                $parent.supervisorPictureServer +
                '/api/file?id=' +
                $parent.supervisorPictureFileId +
                '&accessToken=' +
                $parent.supervisorPictureAccessToken +
                '&thumb=medium'
              "
              class="Image"
              v-if="
                $parent.supervisorPictureServer != null &&
                $parent.supervisorPictureFileId != null &&
                $parent.supervisorPictureAccessToken != null
              "
            />
          </div>
          <div class="Lower">
            {{ $parent.supervisorFirstName }}
            <span class="LastName">{{ $parent.supervisorLastName }}</span>
          </div>
        </div>
      </div>

      <div class="Timebar">
        <div class="Background"></div>
        <div class="Planshift" v-if="drawPlanshiftFrom"></div>
        <div
          class="PlanshiftText PlanshiftLeftText"
          v-if="drawPlanshiftFrom && !fakePlanshift"
        >
          {{ drawPlanshiftFrom.format("HH:mm") }}
        </div>
        <div
          class="PlanshiftText PlanshiftCenterText"
          v-bind:style="[
            { 'text-align': fakePlanshift ? 'left' : 'center' },
            { 'padding-left': fakePlanshift ? '5px' : '0px' },
          ]"
        >
          <strong>{{ positionText }}</strong>
        </div>
        <div
          class="PlanshiftText PlanshiftRightText"
          v-if="drawPlanshiftTo && !fakePlanshift"
        >
          {{ drawPlanshiftTo.format("HH:mm") }}
        </div>

        <div
          class="MandatoryPause"
          v-bind:style="[
            {
              'margin-left': 'calc(15% + ' + mandatoryPauseMarginLeftModifier + ')',
            },
            { width: mandatoryPauseWidth },
          ]"
        ></div>
        <div
          class="PlannedPause"
          v-bind:style="[
            {
              'margin-left': 'calc(15% + ' + planPauseMarginLeftModifier + ')',
            },
            { width: planPauseWidth },
          ]"
        ></div>

        <div
          class="Actualshift"
          v-if="drawActualshiftFrom"
          v-bind:style="[
            {
              'margin-left': 'calc(15% + ' + actualShiftMarginLeftModifier + ')',
            },
            { width: actualShiftWidth },
          ]"
        ></div>
        <div
          class="ActualshiftText ActualshiftLeftText"
          v-bind:style="{
            'margin-left': 'calc(15% + ' + actualShiftMarginLeftModifier + ')',
          }"
          v-if="drawActualshiftFrom"
        >
          {{ drawActualshiftFrom.format("HH:mm") }}
        </div>

        <div
          class="Overtime"
          v-if="stateOvertime"
          v-bind:style="[{ width: overtimeWidth }]"
        ></div>

        <div
          class="ActualPause"
          v-for="pause in drawPauses"
          :key="'actualpause_' + pause.id"
          v-bind:style="[
            { 'margin-left': 'calc(15% + ' + pause.marginLeftModifier + ')' },
            { width: pause.width },
          ]"
        >
          <i v-if="pause.lunch" class="fas fa-utensils" style="color: #909090"></i>
        </div>

        <div class="marker6Hours" v-if="fakePlanshift"></div>
        <div class="marker6HoursText" v-if="fakePlanshift">6h</div>
        <div class="marker8Hours" v-if="fakePlanshift"></div>
        <div class="marker8HoursText" v-if="fakePlanshift">8h</div>
        <div class="marker10Hours" v-if="fakePlanshift"></div>
        <div class="marker10HoursText" v-if="fakePlanshift">10h</div>
        <div class="marker12Hours" v-if="fakePlanshift"></div>
        <div class="marker12HoursText" v-if="fakePlanshift">12h</div>

        <div
          v-if="drawPlanshiftFrom"
          class="CurrentTimeVerticalBar"
          v-bind:style="{
            'margin-left': 'calc(15% + ' + currentTimeMarginLeftModifier + ')',
          }"
        ></div>
        <div
          v-if="drawPlanshiftFrom"
          class="CurrentTimeTextBox"
          v-bind:style="{
            'margin-left': 'calc(15% - 30px + ' + currentTimeMarginLeftModifier + ')',
          }"
        >
          {{ $parent.currentTime.format("HH:mm") }}
        </div>
      </div>

      <div class="Buttons" v-if="!performAction && !errorOnAction && !successOnAction">
        <div class="Text" v-if="state == 'inPause'">
          <div style="margin-top: 7px">
            <strong>Du bist noch in einer Pause</strong><br />
            <small>{{ pauseSubMessage }}</small>
          </div>
        </div>

        <!-- NO PLANSHIFTS FOUND -->
        <div
          class="Area"
          v-if="
            currentPlanshift == null &&
            nextPlanshift == null &&
            !fakePlanshift &&
            !(stateLoginWithoutPlanshift && $parent.loginWithoutPlanshift)
          "
          v-on:click="TapButton('cancel')"
        >
          <div class="Button">
            <i class="fas fa-ban" style="color: #fff"></i>
          </div>
          <strong>Abbrechen</strong>
        </div>

        <div
          class="Area"
          v-if="
            state != 'working' &&
            state != 'inPause' &&
            !showKommen &&
            (!stateLoginWithoutPlanshift || !$parent.loginWithoutPlanshift) &&
            $parent.hasAdminEmployee
          "
          v-on:click="TapButton('adminLogin')"
        >
          <div class="Button">
            <i class="fad fa-user-tie" style="color: #e76e13"></i>
          </div>
          <strong>Supervisor</strong>
        </div>

        <!-- LOG IN -->
        <div
          class="Area"
          v-if="
            showKommen || (stateLoginWithoutPlanshift && $parent.loginWithoutPlanshift)
          "
          v-on:click="TapButton('start')"
        >
          <div class="Button">
            <i class="fad fa-house-return" style="color: #6d8d23"></i>
          </div>
          <strong>{{ showKommenText }}</strong
          ><span v-if="showKommenAsterisk" style="color: #a4a4a4">*</span>
        </div>
        <!-- PAUSE BUTTONS -->
        <div
          class="Area"
          v-if="state == 'working' && $parent.showPauseLeft"
          v-on:click="TapButton('pauseLeft')"
        >
          <div class="Button">
            <i
              class="fad"
              v-bind:class="[
                { 'fa-coffee': $parent.pauseLeftDuration < 15 },
                { 'fa-utensils': $parent.pauseLeftDuration >= 15 },
              ]"
              style="color: #dec92f"
            ></i>
          </div>
          <strong>{{ $parent.pauseLeftDuration }} min</strong>
        </div>
        <div
          class="Area"
          v-if="state == 'working' && $parent.showPauseCenter"
          v-on:click="TapButton('pauseCenter')"
        >
          <div class="Button">
            <i
              class="fad"
              v-bind:class="[
                { 'fa-coffee': $parent.pauseCenterDuration < 15 },
                { 'fa-utensils': $parent.pauseCenterDuration >= 15 },
              ]"
              style="color: #dec92f"
            ></i>
          </div>
          <strong>{{ $parent.pauseCenterDuration }} min</strong>
        </div>
        <div
          class="Area"
          v-if="state == 'working' && $parent.showPauseRight"
          v-on:click="TapButton('pauseRight')"
        >
          <div class="Button">
            <i
              class="fad"
              v-bind:class="[
                { 'fa-coffee': $parent.pauseRightDuration < 15 },
                { 'fa-utensils': $parent.pauseRightDuration >= 15 },
              ]"
              style="color: #dec92f"
            ></i>
          </div>
          <strong>{{ $parent.pauseRightDuration }} min</strong>
        </div>
        <!-- END PAUSE -->
        <div
          class="Area"
          v-if="state == 'inPause' && (canEndPause || $parent.supervisorLogin)"
          v-on:click="TapButton('endPause')"
        >
          <div class="Button">
            <i
              class="fad"
              v-bind:class="[
                { 'fa-sign-in-alt': $parent.pauseRightDuration < 15 },
                { 'fa-sign-in-alt': $parent.pauseRightDuration >= 15 },
              ]"
              style="color: #dec92f"
            ></i>
          </div>
          <strong>{{ drawExitPauseMoment.format("HH:mm") }}</strong>
        </div>
        <!-- MANAGER BUTTON PAUSE -->
        <div
          class="Area"
          v-if="state == 'inPause' && !canEndPause && $parent.hasAdminEmployee"
          v-on:click="TapButton('adminLogin')"
        >
          <div class="Button">
            <i class="fad fa-user-tie" style="color: #e76e13"></i>
          </div>
          <strong>Supervisor</strong>
        </div>
        <!-- GO HOME -->
        <div class="Area" v-if="drawExitMoment != null && state == 'working' && canLogoutInTerminal && (!showGehenAsteriskDisadvantage || (showGehenAsteriskDisadvantage && $parent.showPunctualLogoffButton))" v-on:click="TapButton('endShift')">
          <!-- <div class="Area" v-if="drawExitMoment != null && (state== 'working' || (state=='inPause' && canEndPause))" v-on:click="TapButton('endShift')"> -->
          <div class="Button">
            <i class="fad fa-house-leave" style="color: #e6154f"></i>
          </div>
          <strong>{{ drawExitMoment.format("HH:mm") }}</strong
          ><span v-if="showGehenAsteriskAdvantage || showGehenAsteriskDisadvantage" style="color: #a4a4a4">*</span>
        </div>
        <!-- OVERTIME BUTTON -->
        <div class="Area" v-if="state == 'working' && stateOvertime && canLogoutInTerminal" v-on:click="TapButton('endWithOvertime')">
          <div class="Button">
            <i class="fad fa-clock" style="color: #e6154f"></i>
          </div>
          <strong>{{ $parent.currentTime.format("HH:mm") }}</strong>
        </div>
      </div>
      <div class="Buttons" v-if="performAction && !errorOnAction && !successOnAction">
        <div class="ActionMessage">
          {{ actionText }}<br />
          <div id="progressbar" v-if="showProgressBar">
            <div v-bind:style="{ width: progressbarWidth + '%' }"></div>
          </div>
          <div
            class="SubMessage"
            v-if="showProgressBar"
            v-bind:style="{ color: colorActionTimePassed }"
          >
            <span v-if="actionDurationSeconds < 10">Die Anfrage wird verarbeitet</span>
            <span v-if="actionDurationSeconds >= 10 && actionDurationSeconds < 20"
              >Bitte noch etwas Geduld</span
            >
            <span v-if="actionDurationSeconds >= 20"
              >Die Anfrage dauert länger als erwartet</span
            >
            <!--{{ actionDurationSeconds }}.<small>{{ actionDurationMilliseconds }}</small> Sekunden-->
          </div>
        </div>
      </div>
      <div class="Buttons" v-if="successOnAction && !errorOnAction">
        <div class="ActionMessage" style="color: #3062ab">
          <strong>{{ successMessage }}</strong>
          <div class="SubMessage" style="color: #888">
            <strong>{{ successSubMessage }}</strong>
          </div>
        </div>
      </div>
      <div class="Buttons" v-if="errorOnAction">
        <div class="ActionMessage" style="color: #e6154f">
          <strong>Die gewünschte Aktion konnte nicht ausgeführt werden!</strong>
          <div
            class="SubMessage"
            style="color: #a0a0a0; font-size: 9px; line-height: 12px"
          >
            HTTP Code &bull; {{ errorHttpCode }}<br />
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
export default {
  name: "MainOverview",
  props: {},
  data: function () {
    return {
      loadingShifts: true,
      loadingShiftsError: false,

      actualShiftId: null, // Needed to send updates to the backend, like start pause, end shift
      planShiftId: null, // If a valid actualshift is found, then this is set to quickly find the referenced planshift
      MessagesTimeout: undefined, // Variable to destroy the interval which is used to show error-/successmessages
      CalculateButtonsTimeout: undefined,
      CalculateRenderTimeout: undefined,

      autoLogoutTimeout: undefined,

      actionPerformedRedirectToLogin: false,
      didLogout: false,
      successMessageVisibleTime: 8000,

      tamperedByAdmin: false, // This will activate if a admin modifies shifts in the future
      loginPunctual: false,
      loginPunctualMoment: false,
      logoutPunctual: false,
      logoutPunctualMoment: false,

      performAction: false,
      actionText: "",
      startActionMoment: null,
      actionDurationSeconds: 0,
      actionDurationMilliseconds: 0,
      progressbarWidth: "0",
      calculateProgressBarWidth: false,

      errorOnAction: false,
      errorHttpCode: "",
      errorData: "",
      errorMessage: "",
      showErrorMessageMoment: null,

      successOnAction: false,
      successMessage: "",
      successSubMessage: "",
      showSuccessMessageMoment: null,

      stateLoginWithoutPlanshift: false,
      actualshifts: [],
      actualpauses: [],
      planshifts: [],

      visiblePlanshift: null,
      visiblePlanshiftMomentFrom: null,
      visiblePlanshiftMomentTo: null,

      currentPlanshift: null,
      currentPlanshiftMomentFrom: null,
      currentPlanshiftMomentTo: null,
      fakePlanshift: false,

      nextPlanshift: null,
      nextPlanshiftMomentFrom: null,
      nextPlanshiftMomentTo: null,
      positionText: "",

      currentActualPause: null,
      currentPauseDuration: 0,

      state: "notWorking",
      stateOvertime: false,
      showAdmin: false,
      canEndPause: false,
      pauseSubMessage: "",

      showKommen: false,
      showKommenText: "Kommen",
      showKommenAsterisk: false,
      showGehenAsteriskAdvantage: false,
      showGehenAsteriskDisadvantage: false,

      currentTimeMarginLeftModifier: "0px",
      actualShiftMarginLeftModifier: "0px",
      actualShiftWidth: "0px",

      mandatoryPauseMarginLeftModifier: "0px",
      mandatoryPauseWidth: "0px",
      planPauseMarginLeftModifier: "0px",
      planPauseWidth: "0px",

      drawPlanshiftFrom: null,
      drawPlanshiftTo: null,
      drawExitPauseMoment: null,
      drawExitMoment: null,

      drawPauses: [],

      actualshiftMomentFrom: null,
      drawActualshiftFrom: null,
      overtimeWidth: "0%",

      planshiftDuration: 9999999999,
    };
  },
  computed: {
    canLogoutInTerminal: function () {
      if (this.currentPlanshift != null) {
        if (
          this.currentPlanshift.latitudeTo != null &&
          this.currentPlanshift.longitudeTo != null &&
          this.currentPlanshift.gpsRangeTo != null
        ) {
          return this.currentPlanshift.gpsToAllowed;
        }
      }
      return true;
    },
    showProgressBar: function () {
      if (
        this.actionDurationSeconds >= 1 ||
        (this.actionDurationSeconds == 0 && this.actionDurationMilliseconds > 4)
      ) {
        return true;
      }
      return false;
    },
    colorActionTimePassed: function () {
      if (this.actionDurationSeconds < 2) {
        return "#f0f0f0";
      } else if (this.actionDurationSeconds < 5) {
        return "#e0e0e0";
      } else if (this.actionDurationSeconds < 10) {
        return "#d0d0d0";
      } else if (this.actionDurationSeconds < 15) {
        return "#c0c0c0";
      } else if (this.actionDurationSeconds < 20) {
        return "#b0b0b0";
      } else if (this.actionDurationSeconds >= 20) {
        return "#e6154f";
      }
      return "#d0d0d0";
    },
  },
  created: function () {},
  mounted: function () {
    moment.locale("de");
    let view = this;

    this.autoLogoutTimeout = setTimeout(function () {
      view.$parent.employeeLogin = true;
      view.$parent.page = "logout";
    }, 60000);

    view.loadShiftData();

    view.setupSuccessMessageTimerAndErrorMessageTimer();
  },
  destroyed() {
    if (this.MessagesTimeout != undefined) {
      clearTimeout(this.MessagesTimeout);
    }
    if (this.CalculateButtonsTimeout != undefined) {
      clearTimeout(this.CalculateButtonsTimeout);
    }
    if (this.CalculateRenderTimeout != undefined) {
      clearTimeout(this.CalculateRenderTimeout);
    }
    if (this.autoLogoutTimeout != undefined) {
      clearTimeout(this.autoLogoutTimeout);
    }
  },
  methods: {
    haversineDistance: function (oneLat, oneLng, twoLat, twoLng) {
      const toRadians = (latOrLng) => (latOrLng * Math.PI) / 180;

      const phiOne = toRadians(oneLat);
      const lambdaOne = toRadians(oneLng);
      const phiTwo = toRadians(twoLat);
      const lambdaTwo = toRadians(twoLng);
      const deltaPhi = phiTwo - phiOne;
      const deltalambda = lambdaTwo - lambdaOne;

      const a =
        Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
        Math.cos(phiOne) *
          Math.cos(phiTwo) *
          Math.sin(deltalambda / 2) *
          Math.sin(deltalambda / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const radius = 6371e3;
      const distance = radius * c;

      return distance;
    },
    loadShiftData: function () {
      let view = this;
      view.loadingShifts = true;
      view.loadingShiftsError = false;
      view.$parent.LATEST_REQUEST = Date.now();
      axios({
        method: "get",
        headers: {},
        url: "/api/getShifts/?id=" + this.$parent.terminalId + "&token=" + this.$parent.accessToken,
      })
        .then((response) => {
          view.loadingShifts = false;
          view.loadingShiftsError = false;

          let allPlanshifts = response.data.data.planShifts;
          for (let x in allPlanshifts) {
            let xPlan = allPlanshifts[x];
            xPlan.gpsFromAllowed = false;
            xPlan.gpsToAllowed = false;
            if (view.$parent.latitude != null && view.$parent.longitude != null) {
              if (
                allPlanshifts[x].latitudeFrom != null &&
                allPlanshifts[x].longitudeFrom != null &&
                allPlanshifts[x].gpsRangeFrom != null
              ) {
                let distance = view.haversineDistance(
                  view.$parent.latitude,
                  view.$parent.longitude,
                  allPlanshifts[x].latitudeFrom,
                  allPlanshifts[x].longitudeFrom
                );
                distance = distance + 10; // Add a little bit more, cause in the backend the correct Vincenty formula is used
                if (distance < allPlanshifts[x].gpsRangeFrom) {
                  xPlan.gpsFromAllowed = true;
                }
              } else {
                xPlan.gpsFromAllowed = true;
              }
              if (
                allPlanshifts[x].latitudeTo != null &&
                allPlanshifts[x].longitudeTo != null &&
                allPlanshifts[x].gpsRangeTo != null
              ) {
                let distance = view.haversineDistance(
                  view.$parent.latitude,
                  view.$parent.longitude,
                  allPlanshifts[x].latitudeTo,
                  allPlanshifts[x].longitudeTo
                );
                distance = distance + 10; // Add a little bit more, cause in the backend the correct Vincenty formula is used
                if (distance < allPlanshifts[x].gpsRangeTo) {
                  xPlan.gpsToAllowed = true;
                }
              } else {
                xPlan.gpsToAllowed = true;
              }
            } else {
              xPlan.gpsFromAllowed = true;
              xPlan.gpsToAllowed = true;
            }
            view.planshifts.push(xPlan);
          }
          view.planshifts.sort(view.sortByFromAsc);

          view.actualshifts = response.data.data.actualShifts;
          view.actualshifts.sort(view.sortByFromDesc);

          view.actualpauses = response.data.data.actualPauses;
          view.actualpauses.sort(view.sortByFromAsc);

          view.calculateRenderContent();
        })
        .catch(function (error) {
          if (error && error.response && error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.$parent.MAINTENANCE = true;
            }
          }
          view.loadingShifts = false;
          view.loadingShiftsError = true;
          view.setError(error);
          console.log(error);
          setTimeout(function () {
            view.$parent.page = "logout";
            view.$parent.employeeLogin = true;
          }, 7000);
        });
    },
    sortByFromAsc: function (a, b) {
      if (a.from < b.from) {
        return -1;
      }
      if (a.from > b.from) {
        return 1;
      }
      return 0;
    },
    sortByFromDesc: function (a, b) {
      if (a.from < b.from) {
        return 1;
      }
      if (a.from > b.from) {
        return -1;
      }
      return 0;
    },

    calculateRenderContent: function () {
      let view = this;
      view.CalculateRenderTimeout = setTimeout(function calculateRenderContentInner() {
        let current = moment().unix();
        let doDrawing = true;
        view.positionText = "";

        for (let i in view.actualshifts) {
          let elem = view.actualshifts[i];
          let eighteenHours = 18 * 3600;
          if (current + eighteenHours < elem.from) {
            //Ignore this, it is too much in the future
            doDrawing = false;
            continue;
          } else if (current >= elem.from && elem.to != null && elem.to > current) {
            // Logged out punctual
            view.logoutPunctual = true;
            doDrawing = false;
            view.logoutPunctualMoment = moment.unix(elem.to);
            setTimeout(function () {
              view.$parent.page = "logout";
              view.$parent.employeeLogin = true;
            }, 7000);
          } else if (
            current + eighteenHours >= elem.from &&
            elem.to != null &&
            elem.to > current
          ) {
            //Can only happen if someone inserts a new shift in advance in the admin panel
            view.tamperedByAdmin = true;
            doDrawing = false;
            setTimeout(function () {
              view.$parent.page = "logout";
              view.$parent.employeeLogin = true;
            }, 7000);
          } else if (current < elem.from) {
            view.loginPunctual = true;
            doDrawing = false;
            view.loginPunctualMoment = moment.unix(elem.from);
            setTimeout(function () {
              view.$parent.page = "logout";
              view.$parent.employeeLogin = true;
            }, 7000);
          } else if (current >= elem.from && elem.to == null) {
            if (current < elem.from + 16 * 3600) {
              // Currently logged in
              // Draw the shift stuff here
              view.actualShiftId = elem.id;
              view.planShiftId = elem.planshiftId;
              doDrawing = true;

              view.state = "working";
              view.actualshiftMomentFrom = moment.unix(elem.from);
              view.drawActualshiftFrom = moment.unix(elem.from);
              break;
            }
          } else if (elem.to != null && elem.to < current) {
            // Shift has already ended, the array is sorted desc, so there will be no further important shifts
            doDrawing = true;
            break;
          }
        }

        if (doDrawing) {
          let drawingPlanshift = null;
          if (view.planShiftId != null) {
            for (let i in view.planshifts) {
              let elem = view.planshifts[i];
              if (elem.id == view.planShiftId) {
                drawingPlanshift = elem;
                view.currentPlanshift = elem;
                view.currentPlanshiftMomentFrom = moment.unix(elem.from);
                view.currentPlanshiftMomentTo = moment.unix(elem.to);

                view.drawPlanshiftFrom = moment.unix(elem.from);
                view.drawPlanshiftTo = moment.unix(elem.to);
                //view.stateOvertime = false;
                view.drawExitMoment = moment.unix(view.$parent.currentTime.unix());
                if (view.$parent.positionsMap.has(elem.spotId)) {
                  view.positionText = view.$parent.positionsMap.get(elem.spotId).name;
                }
                if (elem.to - elem.from > 0) {
                  view.planshiftDuration = elem.to - elem.from;
                }
                break;
              }
            }
          } else {
            //Option 1 - Do not search for a planshift and just fake the planshift
            if (view.actualshiftMomentFrom != null) {
              view.positionText = "Zeiterfassung ohne Planschicht";

              view.planshiftDuration = 12 * 3600;

              view.fakePlanshift = true;
              view.drawPlanshiftFrom = view.actualshiftMomentFrom.clone();
              view.drawPlanshiftTo = view.actualshiftMomentFrom.clone().add(12, "hours");

              view.currentPlanshiftMomentFrom = view.actualshiftMomentFrom.clone();
              view.currentPlanshiftMomentTo = view.actualshiftMomentFrom
                .clone()
                .add(12, "hours");
            } else {
              for (let i in view.planshifts) {
                let elem = view.planshifts[i];
                if (elem.gpsFromAllowed) {
                  if (view.$parent.positionsMap.has(elem.spotId)) {
                    view.positionText = view.$parent.positionsMap.get(elem.spotId).name;
                  }
                  if (elem.to - elem.from > 0) {
                    view.planshiftDuration = elem.to - elem.from;
                  }
                  if (elem.from <= current && elem.to >= current) {
                    //Start of planshift is in the past && End of planshift is in the future
                    view.currentPlanshift = elem;
                    view.currentPlanshiftMomentFrom = moment.unix(elem.from);
                    view.currentPlanshiftMomentTo = moment.unix(elem.to);

                    view.drawPlanshiftFrom = moment.unix(elem.from);
                    view.drawPlanshiftTo = moment.unix(elem.to);
                    //view.stateOvertime = false;
                    view.drawExitMoment = moment.unix(view.$parent.currentTime.unix());
                    break;
                  } else if (elem.from > current) {
                    //Next planshift
                    view.nextPlanshift = elem;
                    view.nextPlanshiftMomentFrom = moment.unix(elem.from);
                    view.nextPlanshiftMomentTo = moment.unix(elem.to);

                    view.drawPlanshiftFrom = moment.unix(elem.from);
                    view.drawPlanshiftTo = moment.unix(elem.to);
                    break;
                  }
                }
              }
            }
          }

          view.showGehenAsteriskAdvantage = false;
          view.showGehenAsteriskDisadvantage = false;
          let oneSecondWidth = 497.0 / view.planshiftDuration;

          let visiblePlanshift = null;
          let visiblePlanshiftMomentFrom = null;
          let visiblePlanshiftMomentTo = null;
          if (view.currentPlanshift != null) {
            visiblePlanshift = view.currentPlanshift;
            visiblePlanshiftMomentFrom = view.currentPlanshiftMomentFrom;
            visiblePlanshiftMomentTo = view.currentPlanshiftMomentTo;
          } else if (view.nextPlanshift != null) {
            visiblePlanshift = view.nextPlanshift;
            visiblePlanshiftMomentFrom = view.nextPlanshiftMomentFrom;
            visiblePlanshiftMomentTo = view.nextPlanshiftMomentTo;
          } else if (view.fakePlanshift) {
            visiblePlanshift = true;
            visiblePlanshiftMomentFrom = view.drawPlanshiftFrom;
            visiblePlanshiftMomentTo = view.drawPlanshiftTo;
          }
          if (visiblePlanshift != null) {
            let currentTimeOffsetToPlanshiftStart =
              view.$parent.currentTime.unix() - visiblePlanshiftMomentFrom.unix();
            view.currentTimeMarginLeftModifier =
              currentTimeOffsetToPlanshiftStart * oneSecondWidth + "px";
            if (currentTimeOffsetToPlanshiftStart * oneSecondWidth < -72) {
              view.currentTimeMarginLeftModifier = "-2000px";
            }
          } else {
            view.currentTimeMarginLeftModifier = "-2000px";
          }

          if (view.planshiftDuration >= 25200 && !view.fakePlanshift) {
            // Only show the mandatory pause timeframe bar, if shift is greater or equal 7 hours
            // Otherwise you won't see the border-radius of the planshift, and the whole planshiftshift-bar will simply be covered in a darker tone
            let mandatoryPauseOffsetToPlanshiftStart = Math.max(
              0,
              view.planshiftDuration - 23400
            );
            view.mandatoryPauseMarginLeftModifier =
              mandatoryPauseOffsetToPlanshiftStart * oneSecondWidth + "px";

            if (visiblePlanshift != null) {
              let tmpMandatoryPauseEnd = Math.min(
                visiblePlanshiftMomentTo.unix(),
                visiblePlanshiftMomentFrom.unix() + 23400
              );
              let tmpMandatoryPauseStart = Math.max(
                visiblePlanshiftMomentFrom.unix(),
                visiblePlanshiftMomentTo.unix() - 23400
              );
              let mandatoryPauseTimeframe = tmpMandatoryPauseEnd - tmpMandatoryPauseStart;
              view.mandatoryPauseWidth = mandatoryPauseTimeframe * oneSecondWidth + "px";
            } else {
              view.mandatoryPauseWidth = "0px";
            }
          }

          if (visiblePlanshift != null && visiblePlanshift.planPauseStart != null) {
            let offset =
              visiblePlanshift.planPauseStart - visiblePlanshiftMomentFrom.unix();
            view.planPauseMarginLeftModifier = offset * oneSecondWidth + "px";
            view.planPauseWidth =
              visiblePlanshift.planPauseDurationInSeconds * oneSecondWidth + "px";
          }
          if ( view.actualshiftMomentFrom != null && view.currentPlanshiftMomentFrom != null ) {
            view.state = "working";
            let actualShiftStartOffsetToPlanshiftStart =
              view.actualshiftMomentFrom.unix() - view.currentPlanshiftMomentFrom.unix();
            view.actualShiftMarginLeftModifier =
              actualShiftStartOffsetToPlanshiftStart * oneSecondWidth + "px";

            let actualshiftWidthToCurrentTime =
              view.$parent.currentTime.unix() - view.actualshiftMomentFrom.unix();
            view.actualShiftWidth = actualshiftWidthToCurrentTime * oneSecondWidth + "px";

            let overtimeWidthToCurrentTime =
              view.$parent.currentTime.unix() - view.currentPlanshiftMomentTo.unix();
            view.overtimeWidth = overtimeWidthToCurrentTime * oneSecondWidth + "px";

            view.drawExitMoment = moment.unix(view.$parent.currentTime.unix());
            if (view.currentPlanshiftMomentTo.unix() < current) {
              view.drawExitMoment = moment.unix(view.currentPlanshiftMomentTo.unix());
              view.showGehenAsteriskDisadvantage = true;
            } else if ( view.currentPlanshiftMomentTo.unix() - view.$parent.p21 * 60 < current && current < view.currentPlanshiftMomentTo.unix() + view.$parent.p22 * 60 ) {
              view.drawExitMoment = moment.unix(view.currentPlanshiftMomentTo.unix());
              view.showGehenAsteriskAdvantage = true;
            }

            view.drawPauses = [];
            if (view.actualShiftId != null) {
              for (let i in view.actualpauses) {
                let elem = view.actualpauses[i];
                let obj = {
                  id: elem.id,
                  marginLeftModifier: "200%",
                  width: "0px",
                  lunch: false,
                };
                if (elem.shiftId == view.actualShiftId) {
                  if (
                    elem.endRequired &&
                    !elem.endedByUser &&
                    elem.from + elem.pauseDuration * 60 < view.$parent.currentTime / 1000
                  ) {
                    //End is required and the user has not ended yet
                    //Modify the end to simulate the pause is still running
                    elem.to = view.$parent.currentTime / 1000 + 30;
                  }

                  let offset = elem.from - view.currentPlanshiftMomentFrom.unix();
                  let marginLeftModifier = offset * oneSecondWidth + "px";
                  obj.marginLeftModifier = marginLeftModifier;

                  let widthTo = elem.to - elem.from;
                  let width = widthTo * oneSecondWidth + "px";
                  obj.width = width;

                  if (elem.to - elem.from >= 60 * 30) {
                    obj.lunch = true;
                  }
                  if (
                    elem.from <= view.$parent.currentTime / 1000 &&
                    view.$parent.currentTime / 1000 <= elem.to
                  ) {
                    view.currentActualPause = elem;
                    view.state = "inPause";
                    view.currentPauseDuration =
                      (view.$parent.currentTime / 1000 - elem.from) / 60;
                    view.canEndPause = elem.canEndEarlier;
                    let pauseEndsInSeconds = elem.to - view.$parent.currentTime / 1000;
                    if (elem.endRequired) {
                      if (
                        !elem.endedByUser &&
                        elem.from + elem.pauseDuration * 60 <
                          view.$parent.currentTime / 1000
                      ) {
                        view.pauseSubMessage = "Du kannst die Pause beenden";
                        view.canEndPause = true;
                      } else if (view.canEndPause) {
                        view.pauseSubMessage = "Du kannst die Pause vorzeitig beenden";
                      } else {
                        if (pauseEndsInSeconds <= 10) {
                          view.pauseSubMessage = "Warte noch 10 Sekunden";
                        } else if (pauseEndsInSeconds < 60) {
                          view.pauseSubMessage =
                            "Warte noch " + Math.ceil(pauseEndsInSeconds) + " Sekunden";
                        } else if (Math.ceil(pauseEndsInSeconds / 60) == 1) {
                          view.pauseSubMessage = "Komm in einer Minute wieder zurück";
                        } else {
                          view.pauseSubMessage =
                            "Komm in " +
                            Math.ceil(pauseEndsInSeconds / 60) +
                            " Minuten wieder zurück";
                        }
                      }
                    } else {
                      if (view.canEndPause) {
                        view.pauseSubMessage = "Du kannst die Pause vorzeitig beenden";
                      } else {
                        view.pauseSubMessage =
                          "Pause endet automatisch um " +
                          moment.unix(elem.to).format("HH:mm") +
                          " Uhr";
                      }
                    }
                    view.drawExitPauseMoment = moment.unix(
                      view.$parent.currentTime.unix()
                    );
                  }
                }
                view.drawPauses.push(obj);
              }
            }
          }
        }
        view.CalculateRenderTimeout = setTimeout(calculateRenderContentInner, 30000);
      }, 0);
      view.calculateButtons();
    },

    calculateButtons: function () {
      let view = this;
      view.CalculateButtonsTimeout = setTimeout(function doButtons() {
        view.stateLoginWithoutPlanshift = false;
        view.showKommen = false;
        view.showKommenAsterisk = false;
        let current = moment().unix();
        view.showKommenText = view.$parent.currentTime.format("HH:mm");
        if (view.state == "notWorking") {
          view.stateLoginWithoutPlanshift = true;

          const toleranceT11 = view.$parent.employeeUseIndividualTolerances ? view.$parent.employeeT11 : view.$parent.t11;
          if ( view.drawPlanshiftFrom != null && view.drawPlanshiftTo != null && current > view.drawPlanshiftFrom.unix() - toleranceT11 * 60 && current < view.drawPlanshiftTo.unix() ) {
            view.showKommen = true;
            if ( current > view.drawPlanshiftFrom.unix() - view.$parent.p11 * 60 && urrent < view.drawPlanshiftFrom.unix() + view.$parent.p12 * 60 ) {
              view.showKommenText = view.drawPlanshiftFrom.format("HH:mm");
              view.showKommenAsterisk = true;
            }
          } else {
            view.state == "noShifts";
          }
        }
        view.stateOvertime = false;
        if (
          view.currentPlanshiftMomentTo != null &&
          view.currentPlanshiftMomentTo.unix() /* + 60 */ <= current
        ) {
          view.stateOvertime = true;
        }
        view.CalculateButtonsTimeout = setTimeout(doButtons, 20000);
      }, 0); // 0 means instant, but the browser must be ready
    },

    setupSuccessMessageTimerAndErrorMessageTimer: function () {
      let view = this;
      this.performAction = false;
      this.actionText = "";
      this.startActionMoment = null;
      this.actionDurationSeconds = 0;
      this.actionDurationMilliseconds = 0;
      this.performSuccessMessageTimer();
    },

    performSuccessMessageTimer: function () {
      let view = this;

      view.MessagesTimeout = setTimeout(function runMessageTimer() {
        if (view.showErrorMessageMoment != null) {
          let diff = Math.abs(view.showErrorMessageMoment.diff(moment()));
          if (diff > 8000) {
            view.errorOnAction = false;
            view.errorHttpCode = "";
            view.errorData = "";
            view.errorMessage = "";
            view.showErrorMessageMoment = null;
          }
        }
        if (view.showSuccessMessageMoment != null) {
          let diff = Math.abs(view.showSuccessMessageMoment.diff(moment()));
          if (diff > view.successMessageVisibleTime) {
            view.successOnAction = false;
            view.successMessage = "";
            view.successSubMessage = "";
            view.showSuccessMessageMoment = null;
            view.resetToLogin();
          }
        }
        if (view.startActionMoment != null) {
          let diff = Math.abs(view.startActionMoment.diff(moment()));
          //diff = diff / 100 * 90;
          view.actionDurationSeconds = Math.floor(diff / 1000);
          view.actionDurationMilliseconds = Math.round(
            (diff - view.actionDurationSeconds * 1000.0) / 100,
            1
          );
          if (view.calculateProgressBarWidth) {
            view.progressbarWidth = Math.min(95, (100 / 30) * view.actionDurationSeconds);
          }
          if (diff > 40000) {
            view.performAction = false;
            view.actionText = "";
            view.startActionMoment = null;
            view.actionDurationSeconds = 0;
            view.actionDurationMilliseconds = 0;
            let customError = {
              response: {
                status: "499*",
                data: { message: "Automatischer Abbruch nach 40 Sekunden" },
              },
            };
            view.setError(customError);
          }
        }
        view.MessagesTimeout = setTimeout(runMessageTimer, 2000);
      }, 0);
    },

    Rating: function (rating) {
      let view = this;
      let url =
        "/api/actualShift/" +
        view.actualShiftId +
        "/feedback/" +
        rating +
        "/?id=" +
        this.$parent.terminalId +
        "&token=" +
        this.$parent.accessToken;
      view.Send(url, "feedback", 0);
    },

    TapButton: function (key) {
      let view = this;
      view.autoLogoutStart = moment();

      if (this.autoLogoutTimeout != undefined) {
        clearTimeout(this.autoLogoutTimeout);
      }
      this.autoLogoutTimeout = setTimeout(function () {
        view.$parent.employeeLogin = true;
        view.$parent.page = "logout";
      }, 60000);

      if (key == "cancel") {
        this.$parent.page = "logout";
      } else if (key == "start") {
        view.actionText = "Bitte warten - Arbeitszeit wird gestartet";
        view.startActionMoment = moment();
        view.performAction = true;
        view.$parent.showLogout = false;
        let url =
          "/api/startShift/?id=" +
          this.$parent.terminalId +
          "&token=" +
          this.$parent.accessToken;
        view.Send(url, "start", 0);
      } else if (key == "pauseLeft" || key == "pauseCenter" || key == "pauseRight") {
        view.actionText = "Bitte warten - Pause wird gestartet";
        view.startActionMoment = moment();
        view.performAction = true;
        view.calculateProgressBarWidth = true;
        view.$parent.showLogout = false;
        if (key == "pauseLeft") {
          let url =
            "/api/actualShift/" +
            view.actualShiftId +
            "/startPauseLeftButton/?id=" +
            this.$parent.terminalId +
            "&token=" +
            this.$parent.accessToken;
          view.Send(url, "pauseStart", 1);
        } else if (key == "pauseCenter") {
          let url =
            "/api/actualShift/" +
            view.actualShiftId +
            "/startPauseCenterButton/?id=" +
            this.$parent.terminalId +
            "&token=" +
            this.$parent.accessToken;
          view.Send(url, "pauseStart", 2);
        } else if (key == "pauseRight") {
          let url =
            "/api/actualShift/" +
            view.actualShiftId +
            "/startPauseRightButton/?id=" +
            this.$parent.terminalId +
            "&token=" +
            this.$parent.accessToken;
          view.Send(url, "pauseStart", 3);
        }
      } else if (key == "endPause") {
        view.actionText = "Bitte warten - Pause wird beendet";
        view.startActionMoment = moment();
        view.performAction = true;
        view.calculateProgressBarWidth = true;
        view.$parent.showLogout = false;
        let url =
          "/api/actualShift/" +
          view.actualShiftId +
          "/actualPause/" +
          view.currentActualPause.id +
          "/endPauseButton/?id=" +
          this.$parent.terminalId +
          "&token=" +
          this.$parent.accessToken;
        view.Send(url, "endPause", 0);
      } else if (key == "endShift") {
        view.actionText = "Bitte warten - Arbeitszeit wird beendet";
        view.startActionMoment = moment();
        view.performAction = true;
        view.calculateProgressBarWidth = true;
        view.$parent.showLogout = false;
        let url = "/api/actualShift/" + view.actualShiftId + "/endShift/?id=" + this.$parent.terminalId + "&token=" + this.$parent.accessToken;
        view.Send(url, "endShift", 1);
      } else if (key == "endWithOvertime") {



        view.actionText = "Bitte warten - Arbeitszeit wird beendet";
        view.startActionMoment = moment();
        view.performAction = true;
        view.calculateProgressBarWidth = true;
        view.$parent.showLogout = false;
        let url = "/api/actualShift/" + view.actualShiftId + "/endShift/?id=" + this.$parent.terminalId + "&token=" + this.$parent.accessToken + "&overtimeRequested=true";

        if(view.showGehenAsteriskDisadvantage && this.$parent.showPunctualLogoffButton){
          // We show the punctual Logoff button as well as the normal Logoff button, the employee pressed the normal logoff button
          // Thus we want to print the message "go to your supervisor"
          view.Send(url, "endShift", 2);
        }else{
          // He pressed the normal logoff button, however it is actually over the planned shift. 
          // But because we dont show the other button, the message should be nicer
          view.Send(url, "endShift", 3);
        }

      } else if (key == "adminLogin") {
        view.$parent.employeeLogin = false;
        view.$parent.page = "login-4-digit";
      }
    },

    Send: function (url, successMessage, button) {
      let view = this;
      view.$parent.LATEST_REQUEST = Date.now();
      axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          view.resetAction();
          setTimeout(function () {
            view.setSuccessMessage(successMessage, button);
          }, 500);
        })
        .catch(function (error) {
          if (error && error.response && error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.$parent.MAINTENANCE = true;
            }
          }
          view.resetAction();
          view.setError(error);
          console.log(error);
        });
    },

    setError: function (error) {
      let view = this;
      view.errorOnAction = true;
      view.showErrorMessageMoment = moment();
      if (error.response) {
        view.errorHttpCode = error.response.status;
        view.errorData = error.response.data;
        if (view.errorData.message) {
          view.errorMessage = view.errorData.message;
        } else {
          view.errorMessage = view.errorData;
        }
      }
    },

    setSuccessMessage: function (type, mode) {
      let view = this;
      let tmp = moment();
      view.successOnAction = true;
      view.showSuccessMessageMoment = moment();
      if (type == "pauseStart") {
        if (mode == 1) {
          tmp.add(view.$parent.pauseLeftDuration, "minutes");
          view.successMessage =
            "Du hast eine " + view.$parent.pauseLeftDuration + " Minuten Pause begonnen";
          if (view.$parent.canEndEarlierLeft && view.$parent.endRequiredLeft) {
            //Can end before planned, and manual ending is required
            view.successSubMessage = "Du kannst die Pause jederzeit beenden";
          } else if (view.$parent.canEndEarlierLeft && !view.$parent.endRequiredLeft) {
            //Can end before planned, otherwise it ends automatically
            view.successSubMessage = "Du kannst die Pause vorzeitig beenden";
          } else if (!view.$parent.canEndEarlierLeft && view.$parent.endRequiredLeft) {
            //Can NOT end before planned, and manual ending is required
            view.successSubMessage =
              "Beende die Pause pünklich um " + tmp.format("HH:mm") + " Uhr";
          } else {
            //Can NOT end before planned, and it ends automatically
            view.successSubMessage =
              "Die Pause endet automatisch um " + tmp.format("HH:mm") + " Uhr";
          }
        } else if (mode == 2) {
          tmp.add(view.$parent.pauseCenterDuration, "minutes");
          view.successMessage =
            "Du hast eine " +
            view.$parent.pauseCenterDuration +
            " Minuten Pause begonnen";
          if (view.$parent.canEndEarlierCenter && view.$parent.endRequiredCenter) {
            //Can end before planned, and manual ending is required
            view.successSubMessage = "Du kannst die Pause jederzeit beenden";
          } else if (
            view.$parent.canEndEarlierCenter &&
            !view.$parent.endRequiredCenter
          ) {
            //Can end before planned, otherwise it ends automatically
            view.successSubMessage = "Du kannst die Pause vorzeitig beenden";
          } else if (
            !view.$parent.canEndEarlierCenter &&
            view.$parent.endRequiredCenter
          ) {
            //Can NOT end before planned, and manual ending is required
            view.successSubMessage =
              "Beende die Pause pünklich um " + tmp.format("HH:mm") + " Uhr";
          } else {
            //Can NOT end before planned, and it ends automatically
            view.successSubMessage =
              "Die Pause endet automatisch um " + tmp.format("HH:mm") + " Uhr";
          }
        } else {
          tmp.add(view.$parent.pauseRightDuration, "minutes");
          view.successMessage =
            "Du hast eine " + view.$parent.pauseRightDuration + " Minuten Pause begonnen";
          if (view.$parent.canEndEarlierRight && view.$parent.endRequiredRight) {
            //Can end before planned, and manual ending is required
            view.successSubMessage = "Du kannst die Pause jederzeit beenden";
          } else if (view.$parent.canEndEarlierRight && !view.$parent.endRequiredRight) {
            //Can end before planned, otherwise it ends automatically
            view.successSubMessage = "Du kannst die Pause vorzeitig beenden";
          } else if (!view.$parent.canEndEarlierRight && view.$parent.endRequiredRight) {
            //Can NOT end before planned, and manual ending is required
            view.successSubMessage =
              "Beende die Pause pünklich um " + tmp.format("HH:mm") + " Uhr";
          } else {
            //Can NOT end before planned, and it ends automatically
            view.successSubMessage =
              "Die Pause endet automatisch um " + tmp.format("HH:mm") + " Uhr";
          }
        }
      } else if (type == "endPause") {
        view.successMessage =
          "Du hast deine Pause um " + tmp.format("HH:mm") + " Uhr beendet";
        view.successSubMessage = "";
      } else if (type == "endShift") {
        view.didLogout = true;
        if (view.$parent.employeeFeedback) {
          view.successMessageVisibleTime = 15000;
        }
        if (mode == 1) {
          if (view.currentPlanshiftMomentTo.unix() < moment().unix()) {
            view.successMessage =
              "Du wurdest pünktlich um " +
              view.currentPlanshiftMomentTo.format("HH:mm") +
              " Uhr ausgestempelt";
            view.successSubMessage = "";
          } else if (
            view.currentPlanshiftMomentTo.unix() - view.$parent.p21 * 60 <
            moment().unix()
          ) {
            view.successMessage =
              "Du wirst pünktlich um " +
              view.currentPlanshiftMomentTo.format("HH:mm") +
              " Uhr ausgestempelt";
            view.successSubMessage = "";
          } else {
            view.successMessage =
              "Du wurdest um " + moment().format("HH:mm") + " Uhr ausgestempelt";
            view.successSubMessage = "";
          }
        } else if (mode == 2) {
          view.successMessage =
            "Du hast Überstunden bis " + moment().format("HH:mm") + " Uhr beantragt";
          view.successSubMessage = "Melde dich bei deinem Vorgesetzten";
        } else if (mode == 3) {
            view.successMessage =
              "Du wurdest um " + moment().format("HH:mm") + " Uhr ausgestempelt";
          view.successSubMessage = "";
        } else {
        }
      } else if (type == "feedback") {
        view.didLogout = false;
        view.successMessage = "Vielen Dank für dein Feedback";
        view.successSubMessage = "";
        view.successMessageVisibleTime = 2000;
      } else if (type == "start") {
        if (
          view.currentPlanshiftMomentFrom != null &&
          view.currentPlanshiftMomentFrom.unix() < moment().unix() &&
          view.currentPlanshiftMomentFrom.unix() + view.$parent.p12 * 60 > moment().unix()
        ) {
          view.successMessage =
            "Du wurdest pünktlich um " +
            view.currentPlanshiftMomentFrom.format("HH:mm") +
            " Uhr eingestempelt";
          view.successSubMessage = "";
        } else if (
          view.nextPlanshiftMomentFrom != null &&
          view.nextPlanshiftMomentFrom.unix() > moment().unix() &&
          view.nextPlanshiftMomentFrom.unix() - view.$parent.p11 * 60 < moment().unix()
        ) {
          view.successMessage =
            "Du wirst pünktlich um " +
            view.nextPlanshiftMomentFrom.format("HH:mm") +
            " Uhr eingestempelt";
          view.successSubMessage = "";
        } else {
          view.successMessage =
            "Du wurdest um " + moment().format("HH:mm") + " Uhr eingestempelt";
          view.successSubMessage = "";
        }
      }
      view.actionPerformedRedirectToLogin = true;
    },

    resetAction: function () {
      let view = this;
      view.$parent.supervisorLogin = false;
      view.$parent.employeeLogin = true;
      view.startActionMoment = null;
      view.calculateProgressBarWidth = false;
      view.progressbarWidth = 100;
      setTimeout(function () {
        view.performAction = false;
        view.$parent.showLogout = true;
      }, 500);
    },

    resetToLogin: function () {
      let view = this;
      if (view.$parent.defaultLogin == "4digit") {
        view.$parent.page = "login-4-digit";
      } else if (view.$parent.defaultLogin == "qr") {
        view.$parent.page = "login-qr";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.MainOverview {
}
.LoadingEmployeeData {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  width: calc(100% - 40px);
  height: 100px;
  text-align: center;
  overflow: hidden;
}
.LoadingEmployeeData > .Upper {
  height: 50px;
  overflow: hidden;
  color: #808080;
  background-color: #fff;
  padding-top: 25px;
}
.LoadingEmployeeData > .Lower {
  padding-top: 2px;
  height: 25px;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #808080;
  font-size: 17px;
}

.EmployeeData {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  width: calc(100% - 40px);
  height: 100px;
  text-align: center;
  overflow: hidden;
}
.EmployeeData > .Left {
  float: left;
  width: 50%;
}
.EmployeeData > .Left > .Upper {
  height: 75px;
  overflow: hidden;
  color: #808080;
  background-color: #fff;
}
.EmployeeData > .Left > .Upper > .Image {
  width: 75px;
  height: 75px;
  border-radius: 15px;
}
.EmployeeData > .Left > .Lower {
  padding-top: 2px;
  height: 25px;
  overflow: hidden;
  font-family: DistrictProMedium;
  color: #808080;
  font-size: 21px;
  letter-spacing: 1px;
}
.EmployeeData > .Left > .Lower > .LastName {
  font-weight: bold;
  padding-left: 5px;
}

.EmployeeData > .Center {
  float: left;
  width: 100%;
}
.EmployeeData > .Center > .Upper {
  height: 75px;
  overflow: hidden;
  color: #808080;
  background-color: #fff;
}
.EmployeeData > .Center > .Upper > .Image {
  width: 75px;
  height: 75px;
  border-radius: 15px;
}
.EmployeeData > .Center > .Lower {
  padding-top: 2px;
  height: 25px;
  overflow: hidden;
  font-family: DistrictProMedium;
  color: #808080;
  font-size: 21px;
  letter-spacing: 1px;
}
.EmployeeData > .Center > .Lower > .LastName {
  font-weight: bold;
  padding-left: 5px;
}

.EmployeeData > .Right {
  height: 100px;
  overflow: hidden;
  background-color: #fff;
  float: right;
  width: 50%;
  font-family: DistrictProMedium;
  color: #808080;
}
.EmployeeData > .Right > .Header {
  font-family: DistrictProBold;
  color: #808080;
  font-size: 17px;
  padding-top: 5px;
  padding-bottom: 15px;
  letter-spacing: 1px;
}
.EmployeeData > .Right > .Body {
  font-family: DistrictProBook;
  color: #808080;
  font-size: 14px;
  letter-spacing: 1px;
}

.EmployeeData > .Right > .Upper {
  height: 75px;
  overflow: hidden;
  color: #808080;
  background-color: #fff;
}
.EmployeeData > .Right > .Upper > .Image {
  width: 75px;
  height: 75px;
  border-radius: 15px;
}
.EmployeeData > .Right > .Lower {
  padding-top: 2px;
  height: 25px;
  overflow: hidden;
  font-family: DistrictProMedium;
  color: #e76f13;
  font-size: 21px;
  letter-spacing: 1px;
}
.EmployeeData > .Right > .Lower > .LastName {
  font-weight: bold;
  padding-left: 5px;
}

.Timebar {
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  width: calc(100% - 40px);
  height: 100px;
  text-align: center;
  overflow: hidden;
}
.Timebar .Background {
  margin-top: 10px;
  background-color: #e5e5e5;
  width: 100%;
  height: 55px;
  border-radius: 10px;
}
.Timebar .Planshift {
  margin-top: -45px;
  margin-left: 15%;
  background-color: #ccc;
  width: 70%;
  height: 45px;
  border-radius: 10px;
}
.Timebar .PlanshiftText {
  font-family: DistrictProBook;
  font-size: 11px;
  color: #777;
  margin-top: -44px;
  margin-left: 15%;
  width: 70%;
  height: 44px;
  box-sizing: border-box;
}
.Timebar .PlanshiftLeftText {
  text-align: left;
  padding-left: 3px;
}
.Timebar .PlanshiftCenterText {
  text-align: center;
  text-shadow: rgba(255, 255, 255, 1) 0px 0px 3px;
  z-index: 99;
}
.Timebar .PlanshiftRightText {
  text-align: right;
  padding-right: 3px;
}

.Timebar .MandatoryPause {
  margin-top: -45px;
  margin-left: 15%;
  background-color: #bbb;
  width: 70%;
  height: 45px;
}
.Timebar .PlannedPause {
  margin-top: -45px;
  margin-left: 15%;
  background-color: #888;
  width: 70%;
  height: 45px;
}

.Timebar .Actualshift {
  margin-top: -28px;
  margin-left: 200%;
  background-color: #6d8d23;
  width: 0%;
  height: 28px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Timebar .ActualshiftText {
  font-family: DistrictProBook;
  font-size: 13px;
  color: #fff;
  margin-top: -28px;
  margin-left: 200%;
  width: 100px;
  height: 28px;
  box-sizing: border-box;
  line-height: 28px;
  font-weight: bold;
}
.Timebar .ActualshiftLeftText {
  text-align: left;
  padding-left: 3px;
}

.Timebar .ActualPause {
  margin-top: -28px;
  margin-left: 200%;
  background-color: #dec92f;
  width: 0%;
  height: 28px;
  line-height: 28px;
}

.Timebar .Overtime {
  margin-top: -28px;
  margin-left: 85%;
  background-color: #e6154f;
  width: 0%;
  height: 28px;
}

.Timebar .marker6Hours {
  border-left: 1px solid #777;
  height: 44px;
  margin-top: -44px;
  margin-left: 50%;
}
.Timebar .marker6HoursText {
  font-family: DistrictProBook;
  color: #777;
  height: 44px;
  margin-top: -44px;
  margin-left: calc(50% + 1px);
  width: 25px;
  font-size: 10px;
  text-align: left;
  padding-left: 2px;
}

.Timebar .marker8Hours {
  border-left: 1px solid #777;
  height: 44px;
  margin-top: -44px;
  margin-left: 61.6%;
}
.Timebar .marker8HoursText {
  font-family: DistrictProBook;
  color: #777;
  height: 44px;
  margin-top: -44px;
  margin-left: calc(61.6% + 1px);
  width: 25px;
  font-size: 10px;
  text-align: left;
  padding-left: 2px;
}

.Timebar .marker10Hours {
  border-left: 1px solid #777;
  height: 44px;
  margin-top: -44px;
  margin-left: 73.3%;
}
.Timebar .marker10HoursText {
  font-family: DistrictProBook;
  color: #777;
  height: 44px;
  margin-top: -44px;
  margin-left: calc(73.3% + 1px);
  width: 25px;
  font-size: 10px;
  text-align: left;
  padding-left: 2px;
}

.Timebar .marker12Hours {
  border-left: 1px solid #777;
  height: 44px;
  margin-top: -44px;
  margin-left: 85%;
}
.Timebar .marker12HoursText {
  font-family: DistrictProBook;
  color: #777;
  height: 44px;
  margin-top: -44px;
  margin-left: calc(85% + 1px);
  width: 25px;
  font-size: 10px;
  text-align: left;
  padding-left: 2px;
}

.Timebar .CurrentTimeVerticalBar {
  height: 75px;
  margin-top: -65px;
  margin-left: 25%;
  width: 3px;
  background-color: #3062ab;
}
.Timebar .CurrentTimeTextBox {
  font-family: DistrictProBook;
  height: 25px;
  margin-left: calc(25% - 30px);
  width: 60px;
  background-color: #3062ab;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
}

.Buttons {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  width: calc(100% - 40px);
  height: 105px;
  text-align: center;
  overflow: hidden;
}
.Buttons > .Text {
  display: inline-block;
  margin-top: 0px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #fff;
  width: 250px;
  height: 105px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #808080;
  font-size: 17px;
  line-height: 30px;
}
.Buttons > .Area {
  display: inline-block;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #fff;
  width: 70px;
  height: 105px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #808080;
  font-size: 15px;
}
.Buttons > .Area > .Button {
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  height: 70px;
  width: 70px;
  background-color: #e5e5e5;
  border-radius: 15px;
  line-height: 70px;
  text-align: center;
  font-family: DistrictProBold;
  font-size: 45px;
}

.Buttons > .ActionMessage {
  display: inline-block;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #fff;
  height: 105px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #808080;
  font-size: 17px;
  line-height: 30px;
  font-weight: bold;
}
.Buttons > .ActionMessage > .SubMessage {
  font-family: DistrictProBook;
  font-size: 13px;
  font-weight: 100;
}

#progressbar {
  background-color: #e5e5e5;
  border-radius: 3px;
  /* (height of inner div) / 2 + padding */
  padding: 3px;
}

#progressbar > div {
  background-color: #c0c0c0;
  width: 0%;
  /* Adjust with JavaScript */
  height: 10px;
  border-radius: 2px;

  -webkit-transition: width 0.1s ease-in-out;
  -moz-transition: width 0.1s ease-in-out;
  -o-transition: width 0.1s ease-in-out;
  transition: width 0.1s ease-in-out;
}

.LoadingUp {
  width: calc(800px - (2 * var(--content-margin)));
  height: 365px;
  background-color: #fff;
  overflow: hidden;
  text-align: center;
}
.LoadingUp > .Upper {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 90px;
  margin-bottom: 15px;

  font-family: DistrictProMedium;
  color: #808080;
  font-size: 20px;
}
.LoadingUp > .Middle {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 25px;

  font-family: DistrictProMedium;
  color: #b2b2b2;
  font-size: 22px;
}
.LoadingUp > .Lower {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 25px;
  margin-bottom: 15px;

  font-family: DistrictProBook;
  color: #b2b2b2;
  font-size: 18px;
}

.btn-secondary {
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-block;
  height: 38px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  color: rgb(255, 255, 255);
  background-color: rgb(108, 117, 125);
  border-bottom-color: rgb(108, 117, 125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(108, 117, 125);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgb(108, 117, 125);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgb(108, 117, 125);
  border-top-style: solid;
  border-top-width: 1px;
  cursor: pointer;
  -webkit-border-image: none;
  text-rendering: auto;
  writing-mode: horizontal-tb;
  appearance: button;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  font-size: 16px;
  font-stretch: 100%;
  font-style: bold;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  text-indent: 0px;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  word-spacing: 0px;
}
</style>
