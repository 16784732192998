<template>
  <div class="Dashboard">
    <div class="Header">
      <div class="left">
        <img src="mz-logo.png" class="logo" />
        <img src="mz-logo-text-white.png" />
      </div>
      <div class="right">

        <div style="height: 26px;">
          <div v-if="serverTimeActive" style="color: #aaa; font-size: 11px;">
            <i class="fas fa-exclamation-square"></i> &nbsp;Lokale Zeitabweichung &bull; Serverzeit aktiviert
          </div>
          
        </div>

        {{ currentTime.format("dddd") }}, {{ currentTime.format("DD") }}.{{ currentTime.format("MMMM") }} {{ currentTime.format("YYYY") }}
        <span class="bold"
          >{{ currentTime.format("HH") }}:{{ currentTime.format("mm") }}</span
        >
      </div>
    </div>
    <div class="Content">
      <!-- TERMINAL IS OFFLINE -->
      <div v-if="!YOU_ARE_ONLINE" class="Locked">
        <div class="Upper">
          <div>
            <i class="fad fa-signal-alt-slash fa-3x" style="color: #ba3232"></i>
          </div>
        </div>
        <div class="Middle">
          <div style="color: #ba3232">Keine Internetverbindung</div>
        </div>
        <div class="Lower">
          <ul style="width: 350px; margin-left: 25%; text-align: left">
            <li>
              <small>Prüfe ob alle Kabel ordentlich angesteckt sind</small>
            </li>
            <li><small>Versuche das Gerät Aus-/Einzuschalten</small></li>
            <li>
              <small>Verständige deinen Elektriker/Netzwerktechniker</small>
            </li>
          </ul>
        </div>
      </div>

      <!-- MZ IS ON maintenance -->
      <div v-if="MAINTENANCE && YOU_ARE_ONLINE" class="Locked">
        <div class="Upper">
          <div>
            <i class="fad fa-construction fa-3x" style="color: #e76f13"></i>
          </div>
        </div>
        <div class="Middle">
          <div style="color: #e76f13">Wartungsarbeiten</div>
        </div>
        <div class="Lower">
          <div>
            Im Moment werden dringende Arbeiten am Server vorgenommen<br />
            <small>Es sind keine Eingaben am Terminal möglich</small>
          </div>
        </div>
      </div>

      <!-- IS the Terminal HARDLOCKED then Display the LockedTerminal component -->
      <LockedTerminal
        v-if="
          YOU_ARE_ONLINE && !MAINTENANCE && hardLockedTerminal && page == 'lockedTerminal'
        "
      ></LockedTerminal>

      <!-- Terminal is not HardLocked, Thus make a request to the backend and check if the GET-Parameters are still valid -->
      <div
        v-if="YOU_ARE_ONLINE && !MAINTENANCE && !hardLockedTerminal && terminalLocked"
        class="Locked"
      >
        <div class="Upper">
          <div v-if="!terminalLockedError">
            <i class="fas fa-lock-open fa-3x"></i>
          </div>
          <div v-if="terminalLockedError">
            <i class="fas fa-exclamation fa-3x"></i>
          </div>
        </div>
        <div class="Middle">
          <div v-if="!terminalLockedError">Das Terminal wird entsperrt.</div>
          <div v-if="terminalLockedError">Entsperren fehlgeschlagen</div>
        </div>
        <div class="Lower">
          <div v-if="!terminalLockedError">
            <img src="loading-balls-line.gif" />
          </div>
          <div v-if="terminalLockedError">
            <button type="button" class="btn-secondary" v-on:click="isTerminalUnlocked()">
              Hier klicken - Um das Terminal erneut zu entsperren</button
            ><br /><br />
            <small>{{ terminalErrorMsg }}<br /></small>
            <small class="smaller">{{ terminalErrorMsg2 }}<br /></small>
            <small class="smaller">{{ terminalErrorMsg3 }}</small>
          </div>
        </div>
      </div>

      <!-- Terminal Parameters are valid, load StoreSettings like the default login page (qr,4digit) and default pause times -->
      <div
        v-if="
          YOU_ARE_ONLINE &&
          !MAINTENANCE &&
          !hardLockedTerminal &&
          !terminalLocked &&
          loadingStoreData
        "
        class="Locked"
      >
        <div class="Upper">
          <div v-if="!loadingStoreDataError">
            <i class="fas fa-download fa-3x"></i>
          </div>
          <div v-if="loadingStoreDataError">
            <i class="fas fa-hourglass-start fa-3x"></i>
          </div>
        </div>
        <div class="Middle">
          <div v-if="!loadingStoreDataError">
            Die Betriebseinstellungen werden geladen.
          </div>
          <div v-if="loadingStoreDataError">
            Die Betriebseinstellungen konnten nicht geladen werden.
          </div>
        </div>
        <div class="Lower">
          <div v-if="!loadingStoreDataError">
            <img src="loading-balls-line.gif" />
          </div>
          <div v-if="loadingStoreDataError">
            Es wird in wenigen Sekunden erneut versucht die Betriebseinstellungen zu
            laden.
          </div>
        </div>
      </div>

      <!-- Terminal is initialized and all Data is loaded, display Employee-Login pages -->
      <div
        v-if="
          YOU_ARE_ONLINE &&
          !MAINTENANCE &&
          !hardLockedTerminal &&
          !terminalLocked &&
          !loadingStoreData
        "
      >
        <div v-if="page == 'demo'" style="padding: 30px">
          <button type="button" class="btn btn-primary" v-on:click="temp4Ditit()">
            4Digit
          </button>
          <button type="button" class="btn btn-primary" v-on:click="tempLogin()">
            Login
          </button>
          <button type="button" class="btn btn-primary" v-on:click="Logout()">
            Logout
          </button>
        </div>
        <Login4Digit v-if="page == 'login-4-digit'"></Login4Digit>
        <LoginQR v-if="page == 'login-qr'"></LoginQR>
        <MainOverview v-if="page == 'mainOverview'"></MainOverview>
        <Logout v-if="page == 'logout'"></Logout>
        <TechnicAccess v-if="page == 'technicAccess'"></TechnicAccess>
      </div>
    </div>
    <div class="Footer">
      <!--{{ footerMessage }}-->
      <small style="color: #ccc">{{ JS_VERSION }} &bull; {{ terminalId }}</small>
      <i class="fad fa-signal-alt-slash" style="color: #e5e5e5"></i><!-- It is needed to load the icon once on the terminal when there is still internet access to get it in the cache / because if the no-internet page gets rendered, and this icon was never shown, then it will not be displayed without internet access -->
      <small style="float: right">{{ mpid }}<span v-if="!SOCKET_IS_ONLINE" style="color: #ccc"> &bull; Socket.io</span><!--{{ terminalId }} &bull; {{ accessToken }}--></small>

    </div>
    <div class="BuildInfo" v-if="showBuildInfo">
      <strong style="">{{ buildInfoText }}</strong>
    </div>
    <div class="Logout-Area">
      <div
        class="Button"
        v-on:click="Logout()"
        v-if="page == 'mainOverview' && showLogout"
      >
        <i class="fas fa-ban"></i>
      </div>
      <div
        class="Button"
        v-on:click="SwitchPage('login-qr')"
        v-if="page == 'login-4-digit' && employeeLogin"
      >
        <i class="fas fa-qrcode"></i>
      </div>
      <div
        class="Button"
        v-on:click="Logout()"
        v-if="page == 'login-4-digit' && !employeeLogin"
      >
        <i class="fas fa-ban"></i>
      </div>
      <div
        class="Button"
        v-on:click="SwitchPage('login-4-digit')"
        v-if="page == 'login-qr'"
      >
        <i class="fab fa-buromobelexperte"></i>
      </div>
    </div>
    <div
      style="
        height: 90px;
        width: 90px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 99;
      "
      v-on:click="ClickedTopLeft()"
    ></div>


    <div
      style="
        height: 35px;
        width: 190px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 99;
        
      "
      v-on:click="ClickedBottomLeft()"
    ></div>

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import LockedTerminal from "./LockedTerminal.vue";
import Login4Digit from "./Login4Digit.vue";
import LoginQR from "./LoginQR.vue";
import Logout from "./Logout.vue";
import TechnicAccess from "./TechnicAccess.vue";
import MainOverview from "./MainOverview.vue";
export default {
  name: "Dashboard",
  components: {
    LockedTerminal,
    Login4Digit,
    LoginQR,
    Logout,
    TechnicAccess,
    MainOverview,
  },
  props: {},
  data: function () {
    return {
      JS_VERSION: "1.7", // Version 1.7 introduces the technic access page
      LATEST_REQUEST: Date.now(),

      showBuildInfo: false,
      buildInfoText: "",

      YOU_ARE_ONLINE: true,
      MAINTENANCE: false,
      SOCKET_IS_ONLINE: false,
      clockTimeout: undefined,
      healthCheckTimeout: undefined,

      clockFastTimeout: undefined,
      serverClockTimeout: undefined,
      serverResyncTimeout: undefined,


      trippletap: 0,
      technictap: 0,

      hardLockedTerminal: true,

      terminalLocked: true,
      terminalLockedError: false,
      terminalErrorMsg: "",
      terminalErrorMsg2: "",
      terminalErrorMsg3: "",
      mpid: "",

      loadingStoreData: false,
      loadingStoreDataError: false,

      prevTerminalId: null,
      tempTerminalId: null,
      terminalId: null,
      accessToken: null,
      hideParams: null,

      currentTime: moment(),
      currentTimeUnix: 0,
      thresholdSeconds: 120,
      serverTimeActive: false,

      page: "demo",
      employeeLogin: true,
      employeeFeedback: true,

      hasAdminEmployee: false,
      loginWithoutPlanshift: false,
      employeeFirstName: "-",
      employeeLastName: "-",
      supervisorFirstName: "-",
      supervisorLastName: "-",

      supervisorLogin: false,

      employeeUseIndividualTolerances: false,
      employeeT11: 0,
      employeeT12: 0,
      employeeT21: 0,
      employeeT22: 0,

      employeePictureServer: null,
      employeePictureFileId: null,
      employeePictureAccessToken: null,

      supervisorPictureServer: null,
      supervisorPictureFileId: null,
      supervisorPictureAccessToken: null,

      loadingEmployeeData: false,

      defaultLogin: "4digit",

      latitude: null,
      longitude: null,
      gpsEnabled: null,
      gpsRange: null,

      p11: 0,
      p12: 0,
      p21: 0,
      p22: 0,

      t11: 0,
      t12: 0,
      t21: 0,
      t22: 0,

      pauseLeftDuration: 5,
      showPauseLeft: true,
      canEndEarlierLeft: true,
      endRequiredLeft: false,

      pauseCenterDuration: 15,
      showPauseCenter: false,
      canEndEarlierCenter: true,
      endRequiredCenter: false,

      showPunctualLogoffButton: false,

      pauseRightDuration: 30,
      showPauseRight: true,
      canEndEarlierRight: false,
      endRequiredRight: false,

      showLogout: true,

      footerMessage: "",
      footerChanged: 0,

      positionsMap: {},

      /*
      tagText: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
      monatText: ['Jänner','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
      */
    };
  },
  mounted: function () {
    let view = this;

    if (window.location.href.includes("rc.terminal.meinezeiterfassung.at")) {
      this.showBuildInfo = true;
      this.buildInfoText = "Release Candidate";
    } else if (window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")) {
      this.showBuildInfo = true;
      this.buildInfoText = "LOCALHOST";
    }

    //This is needed to switch instantly to german
    moment.locale("de");
    view.currentTime = moment();
    view.$forceUpdate();

    window.addEventListener(
      "load",
      function (e) {
        if (navigator.onLine) {
          view.YOU_ARE_ONLINE = true;
        } else {
          view.YOU_ARE_ONLINE = false;
        }
      },
      false
    );
    window.addEventListener(
      "online",
      function (e) {
        view.YOU_ARE_ONLINE = true;
      },
      false
    );
    window.addEventListener(
      "offline",
      function (e) {
        view.YOU_ARE_ONLINE = false;
      },
      false
    );

    window.Echo.connector.socket.on("connect", () => {
      view.SOCKET_IS_ONLINE = true;
    });
    window.Echo.connector.socket.on("disconnect", () => {
      view.SOCKET_IS_ONLINE = false;
    });

    window.Echo.channel("maintenance").listen("MaintenanceEvent", (e) => {
      window.location.reload(true);
    });

    /* Calculate Zoom, if display is larger than the raspberry pi 7 zoll display */
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    if (width > 800 || height > 480) {
      let maxZoom = Math.min(width / 800, height / 480);
      document.getElementById("app").style.zoom = maxZoom;
    }

    /* Get GET-Parameters and display them in the footer on the right side */
    var url_string = window.location.href;
    var url = new URL(url_string);
    this.terminalId = url.searchParams.get("id");
    this.accessToken = url.searchParams.get("token");

    this.hideParams = url.searchParams.get("hide");
    if (this.hideParams == "1") {
      window.history.pushState("", "Terminal - MeineZeiterfassung", "/unlocked");
    }

    /* Are there Parameters? If no parameters found => this is external hardware - hardLocked ; Otherwise => SoftLocked, it is our MP Hardware */
    if (
      this.terminalId == null ||
      this.accessToken == null ||
      this.terminalId.length === 0 ||
      this.accessToken.length === 0
    ) {
      this.hardLockedTerminal = true;
      this.page = "lockedTerminal";
    } else {
      this.hardLockedTerminal = false;

      window.Echo.channel("reload-" + view.terminalId).listen(
        "ReloadTerminalEvent",
        (e) => {
          window.location.reload(true);
        }
      );

      view.isTerminalUnlocked();
      view.requestStorePositions();
    }

    //No access to DOM here, if DOM needed use mounted
    window.Echo.channel("test-event").listen("ExampleEvent", (e) => {
      console.log(e);
    });

    window.Echo.channel("storesetting-event").listen("StoreSettingEvent", (e) => {
      console.log(e);
    });

    /*

      Login event from server: --> Change page to logged in

    */

    view.clockTimeout = setTimeout(function updateClock() {
      view.trippletap = 0;
      view.technictap = 0;
      view.clockTimeout = setTimeout(updateClock, 30000);
    }, 30000);

    // Initialer Server-Ping: Hole den Zeitstempel vom Server und bestimme den Modus.
    axios({
      method: "get",
      url: "/api/ping/?id=" + view.terminalId + "&token=" + view.accessToken,
    })
      .then((response) => {
        if ( response && response.data && response.data.message && !isNaN(response.data.message) && response.data.message.toString().length === 10 ) {
          // Setze den Zeitstempel basierend auf der Serverantwort.
          view.currentTimeUnix = response.data.message;
          view.currentTime = moment.unix(view.currentTimeUnix);

          // Vergleiche die Serverzeit mit der aktuellen lokalen Zeit.
          const serverTime = moment.unix(view.currentTimeUnix);
          const localTime = moment();
          const diffSeconds = Math.abs(localTime.diff(serverTime, "seconds"));

          if (diffSeconds < view.thresholdSeconds) {
            // Die Differenz ist klein – nutze die lokale Uhr.
            view.serverTimeActive = false;
            view.startLocalClock();
          } else {
            // Die Differenz ist zu groß – verwende ausschließlich die Serverzeit.
            view.serverTimeActive = true;
            view.startServerClock();
          }
        }
      })
      .catch((error) => {
        console.error("Fehler beim initialen Server-Ping:", error);
      });



    view.healthCheckTimeout = setTimeout(function healthCheck() {
      if (Date.now() - view.LATEST_REQUEST > 900000) {
        // 15 minutes
        view.LATEST_REQUEST = Date.now();
        axios({
          method: "get",
          headers: {},
          url: "/api/ping/?id=" + view.terminalId + "&token=" + view.accessToken,
        })
          .then((response) => {})
          .catch(function (error) {
            if (error && error.response && error.response.status == 503) {
              if (error.response.data && error.response.data.type == "Maintenance") {
                view.MAINTENANCE = true;
              }
            }
          });
      }
      view.healthCheckTimeout = setTimeout(healthCheck, 270000); // 4,5 minutes
    }, 270000); // 4,5 minutes
  },
  destroyed() {
    if (this.clockTimeout != undefined) {
      clearTimeout(this.clockTimeout);
    }
    if (this.clockFastTimeout != undefined) {
      clearTimeout(this.clockFastTimeout);
    }
    if (this.serverClockTimeout != undefined) {
      clearTimeout(this.serverClockTimeout);
    }
    if (this.serverResyncTimeout != undefined) {
      clearTimeout(this.serverResyncTimeout);
    }
    if (this.healthCheckTimeout != undefined) {
      clearTimeout(this.healthCheckTimeout);
    }
  },
  methods: {
    startLocalClock: function () {
      let view = this;
      view.clockFastTimeout = setTimeout(function updateLocalClock() {
        view.currentTimeUnix = moment().unix();
        view.currentTime = moment();
        view.clockFastTimeout = setTimeout(updateLocalClock, 10000);
      }, 10000);
    },
    startServerClock: function () {
      let view = this;
      view.serverClockTimeout = setTimeout(function updateServerClock() {
        view.currentTimeUnix += 20;
        console.log("+10");
        view.currentTime = moment.unix(view.currentTimeUnix);
        view.serverClockTimeout = setTimeout(updateServerClock, 20000);
      }, 20000);
      view.serverResyncTimeout = setTimeout(function resyncServerTime() {
        axios({
          method: "get",
          url: "/api/ping/?id=" + view.terminalId + "&token=" + view.accessToken,
        })
          .then((response) => {
            if ( response && response.data && response.data.message && !isNaN(response.data.message) && response.data.message.toString().length === 10 ) {
              view.currentTimeUnix = response.data.message;
              view.currentTime = moment.unix(view.currentTimeUnix);
            }
          })
          .catch((error) => {
            console.error("Fehler beim erneuten Server-Ping:", error);
          });
          view.serverResyncTimeout = setTimeout(resyncServerTime, 200000);
      }, 200000);
    },
    ClickedTopLeft: function () {
      this.trippletap++;
      if (this.trippletap >= 3) {
        if (this.hideParams == "1") {
          window.history.pushState(
            "",
            "Terminal - MeineZeiterfassung",
            "/?id=" + this.terminalId + "&token=" + this.accessToken + "&hide=1"
          );
        }
        window.location.reload(true);
      }
    },

    ClickedBottomLeft: function () {
      this.technictap++;
      if (this.technictap >= 3) {
        //alert("Techniker Login");
      }
    },



    listenToUnlockTerminalChanged: function () {
      let view = this;
      if (view.tempTerminalId != null) {
        window.Echo.channel("unlock-" + view.prevTerminalId).stopListening("UnlockEvent");
        window.Echo.leave("unlock-" + view.prevTerminalId);
        window.Echo.channel("unlock-" + view.tempTerminalId).listen(
          "UnlockEvent",
          (e) => {
            window.location.href =
              window.location.href +
              "?id=" +
              view.tempTerminalId +
              "&token=" +
              e.accessToken;
          }
        );
      }
    },
    reset: function () {
      this.terminalLocked = true;

      this.page = "login-4-digit";
      this.supervisorLogin = false;

      this.loginWithoutPlanshift = false;
      this.hasAdminEmployee = false;
      this.employeeFirstName = "-";
      this.employeeLastName = "-";
      this.supervisorFirstName = "-";
      this.supervisorLastName = "-";

      this.employeePictureServer = null;
      this.employeePictureFileId = null;
      this.employeePictureAccessToken = null;
      this.supervisorPictureServer = null;
      this.supervisorPictureFileId = null;
      this.supervisorPictureAccessToken = null;

      this.loadingEmployeeData = false;

      this.defaultLogin = "4digit";

      this.p11 = 0;
      this.p12 = 0;
      this.p21 = 0;
      this.p22 = 0;

      this.latitude = null;
      this.longitude = null;
      this.gpsEnabled = null;
      this.gpsRange = null;

      this.t11 = 0;
      this.t12 = 0;
      this.t21 = 0;
      this.t22 = 0;

      this.employeeUseIndividualTolerances = false;
      this.employeeT11 = 0;
      this.employeeT12 = 0;
      this.employeeT21 = 0;
      this.employeeT22 = 0;

      this.pauseLeftDuration = 5;
      this.showPauseLeft = true;
      this.canEndEarlierLeft = true;
      this.endRequiredLeft = false;

      this.pauseCenterDuration = 15;
      this.showPauseCenter = false;
      this.canEndEarlierCenter = true;
      this.endRequiredCenter = false;

      this.showPunctualLogoffButton = false;

      this.pauseRightDuration = 30;
      this.showPauseRight = true;
      this.canEndEarlierRight = false;
      this.endRequiredRight = false;

      this.footerMessage = "";
      this.footerChanged = 0;
    },

    SwitchPage: function (page) {
      this.page = page;
    },

    isTerminalUnlocked: function () {
      let view = this;
      this.terminalLockedError = false;
      let url =
        "/api/isTerminalUnlocked/?id=" +
        this.terminalId +
        "&token=" +
        this.accessToken +
        "&version=" +
        this.JS_VERSION;
      view.footerMessage = "Terminal wird entsperrt...";
      view.footerChanged = Date.now();
      view.LATEST_REQUEST = Date.now();
      axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          view.footerMessage = "Terminal wurde erfolgreich entsperrt";
          view.footerChanged = Date.now();
          if (response.data.data.mpid) {
            view.mpid = response.data.data.mpid;
          }
          view.terminalLocked = false;
          view.requestStoreSettings();
        })
        .catch(function (error) {
          if (error && error.response && error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.MAINTENANCE = true;
            }
          }
          view.footerMessage = "Terminal konnten nicht entsperrt werden";
          view.footerChanged = Date.now();
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            let temp = error.response.data.message.split("\n");
            if (temp[0]) {
              view.terminalErrorMsg = temp[0];
            } else {
              view.terminalErrorMsg = "";
            }
            if (temp[1]) {
              view.terminalErrorMsg2 = temp[1];
            } else {
              view.terminalErrorMsg2 = "";
            }
            if (temp[2]) {
              view.terminalErrorMsg3 = temp[2];
            } else {
              view.terminalErrorMsg3 = "";
            }
          }
          view.terminalLockedError = true;
        });
    },

    Logout: function () {
      this.employeeLogin = true;
      this.page = "logout";
    },
    TechnicAccess: function () {
      this.employeeLogin = true;
      this.page = "technicAccess";
    },

    requestStoreSettings: function () {
      let view = this;
      this.loadingStoreData = true;
      this.loadingStoreDataError = false;
      let url = "/api/storeSetting/?id=" + this.terminalId + "&token=" + this.accessToken;
      view.footerMessage = "Betriebseinstellungen werden geladen...";
      view.footerChanged = Date.now();
      view.LATEST_REQUEST = Date.now();
      axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          view.footerMessage = "Betriebseinstellungen wurden erfolgreich geladen";
          view.footerChanged = Date.now();

          view.p11 = response.data.p11;
          view.p12 = response.data.p12;
          view.p21 = response.data.p21;
          view.p22 = response.data.p22;

          view.t11 = response.data.t11;
          view.t12 = response.data.t12;
          view.t21 = response.data.t21;
          view.t22 = response.data.t22;

          view.latitude = response.data.latitude;
          view.longitude = response.data.longitude;
          view.gpsEnabled = response.data.gpsEnabled;
          view.gpsRange = response.data.gpsRange;

          view.defaultLogin = response.data.defaultLogin;
          view.employeeFeedback = response.data.employeeFeedback;

          view.pauseLeftDuration = response.data.pauseLeftDuration;
          view.showPauseLeft = response.data.showPauseLeft;
          view.canEndEarlierLeft = response.data.canEndEarlierLeft;
          view.endRequiredLeft = response.data.endRequiredLeft;

          view.pauseCenterDuration = response.data.pauseCenterDuration;
          view.showPauseCenter = response.data.showPauseCenter;
          view.canEndEarlierCenter = response.data.canEndEarlierCenter;
          view.endRequiredCenter = response.data.endRequiredCenter;

          view.showPunctualLogoffButton = response.data.showPunctualLogoffButton;

          view.pauseRightDuration = response.data.pauseRightDuration;
          view.showPauseRight = response.data.showPauseRight;
          view.canEndEarlierRight = response.data.canEndEarlierRight;
          view.endRequiredRight = response.data.endRequiredRight;

          view.loadingStoreData = false;
          view.loadingStoreDataError = false;
          if (view.defaultLogin == "4digit") {
            view.page = "login-4-digit";
          } else if (view.defaultLogin == "qr") {
            view.page = "login-qr";
          } else {
            view.page = "login-4-digit";
          }
        })
        .catch(function (error) {
          if (error && error.response && error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.MAINTENANCE = true;
            }
          }
          view.footerMessage = "Betriebseinstellungen konnten nicht geladen werden";
          view.footerChanged = Date.now();
          view.loadingStoreDataError = true;
          console.log(error);
        });
    },

    requestStorePositions: function () {
      let view = this;
      let url =
        "/api/storePositions/?id=" + this.terminalId + "&token=" + this.accessToken;
      view.footerMessage = "Positionen werden geladen...";
      view.footerChanged = Date.now();
      view.LATEST_REQUEST = Date.now();
      axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          view.positionsMap = new Map();
          for (let i in response.data) {
            view.positionsMap.set(response.data[i].mdId, response.data[i]);
          }
        })
        .catch(function (error) {
          if (error && error.response && error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.MAINTENANCE = true;
            }
          }
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Dashboard {
  width: 800px;
  height: 480px;
  background-color: #e5e5e5;
  overflow: hidden;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.Header {
  margin-left: var(--content-margin);
  margin-top: var(--content-margin);
  margin-right: var(--content-margin);
  width: calc(800px - (2 * var(--content-margin)));
  height: 50px;
  background-color: #e5e5e5;
}
.Header .left {
  width: 35%;
  float: left;
}
.Header .left .logo {
  width: 50px;
  height: 50px;
  text-align: left;
}

.Header .right {
  width: 60%;
  float: right;
  text-align: right;
  padding-top: 0px;
  line-height: 30px;
  font-family: "DistrictProBook";
  font-size: 21px;
}
.Header .right .bold {
  font-family: "DistrictProMedium";
  font-weight: bolder;
  padding-left: 7px;
}

.Content {
  margin-left: var(--content-margin);
  margin-right: var(--content-margin);
  margin-top: 15px;
  width: calc(800px - (2 * var(--content-margin)));
  height: 365px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}
.Content > .Locked {
  width: calc(800px - (2 * var(--content-margin)));
  height: 365px;
  background-color: #fff;
  overflow: hidden;
  text-align: center;
}
.Content > .Locked > .Upper {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 90px;
  margin-bottom: 15px;

  font-family: DistrictProMedium;
  color: #808080;
  font-size: 20px;
}
.Content > .Locked > .Middle {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 25px;

  font-family: DistrictProMedium;
  color: #b2b2b2;
  font-size: 22px;
}
.Content > .Locked > .Lower {
  text-align: center;
  width: 100%;
  background-color: #fff;
  margin-top: 25px;
  margin-bottom: 15px;

  font-family: DistrictProBook;
  color: #b2b2b2;
  font-size: 18px;
}

.Footer {
  margin-left: var(--content-margin);
  margin-right: var(--content-margin);
  margin-top: 0px;
  width: calc(800px - (2 * var(--content-margin)) - 30px);
  height: 25px;
  background-color: #e5e5e5;
  color: #b0b0b0;
  border-radius: 15px;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  font-family: DistrictProBook;
  font-size: 12px;
  line-height: 25px;
}
.BuildInfo {
  margin-left: var(--content-margin);
  margin-right: var(--content-margin);
  margin-top: -25px;
  width: calc(800px - (2 * var(--content-margin)) - 30px);
  height: 25px;
  background-color: #e5e5e5;
  color: #E81323;
  overflow: hidden;
  font-family: DistrictProBook;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
}
.Logout-Area {
  margin-left: var(--content-margin);
  margin-right: var(--content-margin);
  margin-top: -95px;
  width: calc(800px - (2 * var(--content-margin)) - 20px);
  height: 70px;
  overflow: hidden;
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
}

.Button {
  display: inline-block;
  background-color: #e5e5e5;
  width: 60px;
  height: 60px;
  overflow: hidden;
  line-height: 60px;
  text-align: center;
  border-radius: 15px;
  color: #fff;
}
.Button > .fas {
  padding-top: 10px;
  font-size: 40px;
}
.Button > .fab {
  padding-top: 10px;
  font-size: 40px;
}
.smaller {
  font-size: 12px;
  color: #bbb;
}
.btn-secondary {
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-block;
  height: 38px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  color: rgb(255, 255, 255);
  background-color: rgb(108, 117, 125);
  border-bottom-color: rgb(108, 117, 125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(108, 117, 125);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgb(108, 117, 125);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgb(108, 117, 125);
  border-top-style: solid;
  border-top-width: 1px;
  cursor: pointer;
  -webkit-border-image: none;
  text-rendering: auto;
  writing-mode: horizontal-tb;
  appearance: button;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  font-size: 16px;
  font-stretch: 100%;
  font-style: bold;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  text-indent: 0px;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  word-spacing: 0px;
}
</style>
