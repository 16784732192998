<template>
  <div class="LoginQR">
    <div class="LoginLeft">
      <div class="Upper">Mitarbeiter - Login</div>
      <div class="Lower">QR - Code</div>
    </div>
    <div class="LoginMiddle">
      <img class="Image" v-bind:src="qrCode" />
    </div>
    <div class="LoginRight">
      <div class="Upper">Guten Tag!</div>
      <div class="Lower">
        Bitte scannen Sie<br />
        mit der MD-App<br />
        den <strong>QR-Code</strong> ein.<br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginQR",
  props: {},
  data: function () {
    return {
      qrCode: "",
      qrLoginTimeout: undefined,
    };
  },
  created: function () {},
  destroyed() {
    let view = this;
    if (this.qrLoginTimeout != undefined) {
      clearTimeout(this.qrLoginTimeout);
    }
    window.Echo.channel("unlockEmployee-" + view.$parent.terminalId).stopListening(
      "UnlockEmployeeEvent"
    );
    window.Echo.leave("unlockEmployee-" + view.$parent.terminalId);
  },
  mounted: function () {
    let view = this;

    window.Echo.channel("unlockEmployee-" + view.$parent.terminalId).listen(
      "UnlockEmployeeEvent",
      (e) => {
        view.$parent.hasAdminEmployee = e.hasAdminEmployee;
        view.$parent.loginWithoutPlanshift = e.loginWithoutPlanshift;
        view.$parent.employeeFirstName = e.employeeFirstName;
        view.$parent.employeeLastName = e.employeeLastName;

        view.$parent.employeeUseIndividualTolerances = e.useIndividualTolerances;
        view.$parent.employeeT11 = e.t11;
        view.$parent.employeeT12 = e.t12;
        view.$parent.employeeT21 = e.t21;
        view.$parent.employeeT22 = e.t22;

        view.$parent.employeePictureServer = e.employeePictureServer;
        view.$parent.employeePictureFileId = e.employeePictureFileId;
        view.$parent.employeePictureAccessToken = e.employeePictureAccessToken;
        view.$parent.page = "mainOverview";
      }
    );
    this.RequestNew();
  },
  methods: {
    RequestNew: function () {
      let view = this;
      view.qrLoginTimeout = setTimeout(function qr() {
        view.RequestLoginQRCode();
        view.qrLoginTimeout = setTimeout(qr, 300000);
      }, 0);
    },
    RequestLoginQRCode: function () {
      let view = this;
      let url =
        "/api/getQRCodeForEmployeeLogin/?id=" +
        this.$parent.terminalId +
        "&token=" +
        this.$parent.accessToken;
      view.$parent.LATEST_REQUEST = Date.now();
      axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          view.qrCode = response.data.image;
          view.$forceUpdate();
        })
        .catch(function (error) {
          if (error && error.response && error.response.status == 503) {
            if (error.response.data && error.response.data.type == "Maintenance") {
              view.$parent.MAINTENANCE = true;
            }
          }
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.LoginQR {
  background-color: #fff;
  width: 100%;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginLeft {
  background-color: #fff;
  float: left;
  width: 248px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginLeft > .Upper {
  margin-top: 100px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBold;
  color: #7f7f7f;
  font-size: 22px;
  line-height: 50px;
}
.LoginLeft > .Lower {
  margin-top: 10px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #7f7f7f;
  font-size: 20px;
  line-height: 50px;
}
.LoginLeft > .Lower > span {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.LoginMiddle {
  background-color: #fff;
  float: left;
  width: 254px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginMiddle > .Image {
  margin-top: 55px;
}

.LoginRight {
  background-color: #fff;
  float: left;
  width: 248px;
  height: 365px;
  text-align: center;
  overflow: hidden;
}
.LoginRight > .Upper {
  margin-top: 100px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBold;
  color: #7f7f7f;
  font-size: 22px;
  line-height: 50px;
}
.LoginRight > .Lower {
  margin-top: 25px;
  background-color: #fff;
  width: 100%;
  height: 200px;
  text-align: center;
  overflow: hidden;
  font-family: DistrictProBook;
  color: #7f7f7f;
  font-size: 20px;
  line-height: 25px;
}
</style>
